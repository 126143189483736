import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BottomCallout from '../../../components/BottomCallout';
import { createTablesAction, listTablesAction } from '../../../redux/order/actions';
import {getEstablishmentAction} from "../../../redux/establishment/actions";

const NewTableModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [branchId, setBranchId] = useState('');
    const [tableNumber, setTableNumber] = useState('');
    const [branches, setBranches] = useState([]);

    const tableAdd = useSelector((state) => state.createTableReducer);
    const getEstablishmentReducer = useSelector((state) => state.getEstablishmentReducer);
    const { loading, error, success } = tableAdd;
    const [isReadOnly, setIsReadOnly] = useState(false);

    useEffect(() => {
        const storedBranchId = localStorage.getItem('branchID');
        if (storedBranchId && parseInt(storedBranchId) > 0) {
            setBranchId(storedBranchId);
            setIsReadOnly(true);
        }
    }, []);

    useEffect(() => {
        dispatch(getEstablishmentAction())
    },[dispatch]);

    useEffect(() => {
        if(getEstablishmentReducer.loading == false) {
            const storedBranches = getEstablishmentReducer?.establishment.branches;
            setBranches(storedBranches);
        }
    }, [getEstablishmentReducer]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (branchId && tableNumber) {
            dispatch(createTablesAction( {branchId, tableNumber}));
            if (!error && success) {
                onClose();
                dispatch(listTablesAction());
            }
        }
    };

    return (
        isOpen ? (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-[#fff7e1] p-6 rounded-lg w-full max-w-lg">
                    <h2 className="text-2xl font-bold mb-4 text-[#151e46]">Add New Table</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Branch</label>
                            <select
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46] cursor-not-allowed"
                                value={branchId}
                                onChange={(e) => setBranchId(e.target.value)}
                                required
                                disabled={isReadOnly}  // Disables the select if isReadOnly is true
                            >
                                <option value="" disabled>Select a branch</option>
                                {branches.map((branch) => (
                                    <option key={branch.id} value={branch.id}>{branch.id}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-[#151e46]">Table Number</label>
                            <input
                                type="text"
                                className="block w-full mt-1 p-2 border border-[#151e46] rounded-md bg-transparent text-[#151e46]"
                                value={tableNumber}
                                onChange={(e) => setTableNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="mr-2 bg-[#F0532F] text-white px-4 py-2 rounded-md"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-[#40b06d] text-white px-4 py-2 rounded-md"
                                disabled={loading}
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </form>
                    {error && <BottomCallout message={error} variant="error"/>}
                    {success && <BottomCallout message="Table added successfully!" variant="success"/>}
                </div>
            </div>
        ) : null
    );
};

export default NewTableModal;
