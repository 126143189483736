import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/Sidebar';
import Table from "../../components/Table";
import FiltersModal from "../../components/FiltersModal";  // Import the FiltersModal component
import './orders.css';
import { useDispatch, useSelector } from "react-redux";
import { listOrdersAction } from "../../redux/order/actions";

const attributeNames = ['orderID', 'tableNumber', 'typeDisplayName', 'totalPaid', 'total', 'updatedAt'];
const attributeDisplayNames = ['Order ID', 'Table', 'Type', 'Total Paid Online', 'Paid', 'Time', 'View'];
const filters = [
    { type: 'text', label: 'Order ID', queryString: 'order_id' },
    { type: 'text', label: 'Table Number', queryString: 'table_id' },
    { type: 'date-between', label: 'Updated At', queryString: 'updated_at' },
];

const Orders = (props) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [queryString, setQueryString] = useState('')
    const [tab, setTab] = useState('All')

    const dispatch = useDispatch();
    const listOrdersReducer = useSelector((state) => state.listOrdersReducer);

    useEffect(() => {
        const fetchData = () => {
            const statusName = tab === "All" ? "" : tab ==="Completed" ? "&status_name=completed" :"&status_name=in_progress"
            dispatch(listOrdersAction(`${queryString}&page=${page}${statusName}&sort=-updated_at`));
        };
        fetchData();
        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, [dispatch, queryString, page, tab]);



    const handleFilterSubmit = (queryStr) => {
        setIsFilterModalOpen(false);
        setQueryString(queryStr)
        const statusName = tab === "All" ? "" : tab ==="Completed" ? "&status_name=completed" :"&status_name=in_progress"
        setPage(1)
        dispatch(listOrdersAction(`${queryString}&page=${page}${statusName}&sort=-updated_at`));
    };



    return (
        <div className="flex ml-80 min-h-screen flex-row justify-between bg-[#fff7e1]">
            <Helmet>
                <title>Orders - Serv</title>
                <meta
                    property="og:title"
                    content="Orders - Serv Restaurant Interface"
                />
            </Helmet>
            <Sidebar />
            <div className="flex flex-col w-full p-4">
                <div className="flex justify-between items-center my-4">
                    <h1 className="text-5xl font-bold text-[#151e46]">Orders</h1>
                    <div className="orders-container04" onClick={() => setIsFilterModalOpen(true)}>
                        <img
                            alt="Filter"
                            src="/filter-list-svgrepo-com%20(1).svg"
                            className="orders-image"
                        />
                        <span className="orders-text01">Filter</span>
                    </div>
                </div>
                <div className="flex justify-center">
                    {listOrdersReducer?.orders &&
                        <Table
                            tabs={['All', 'In Progress', 'Completed']}
                            baseRoute={'/orders'}
                            idFieldName={'orderID'}
                            data={listOrdersReducer?.orders}
                            cols={attributeNames}
                            attributeDisplayNames={attributeDisplayNames}
                            colNums={7}
                            maxPage={listOrdersReducer.countPages}
                            page={page}
                            setPage={setPage}
                            currentTab={tab}
                            setTab={setTab}
                            countRows={listOrdersReducer.countRows}
                        />
                    }
                </div>
            </div>
            {isFilterModalOpen && (
                <FiltersModal
                    title="Filter Orders"
                    filters={filters}
                    onSubmit={handleFilterSubmit}
                    buttonLabel="Apply FiltersModal"
                />
            )}
        </div>
    );
};

export default Orders;
