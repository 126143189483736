import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {useDispatch, useSelector} from "react-redux";
import {loginAction} from "../../redux/auth/actions";
import BottomCallout from "../../components/BottomCallout";
import {Button, TextInput} from "@tremor/react";
import {Link, useHistory} from "react-router-dom";
import SelectBranchModal from "../../components/SelectBranchModal";
import {getEstablishmentAction, getEstablishmentBranchAction} from "../../redux/establishment/actions";
import {getEstablishmentBranchReducer, getEstablishmentReducer} from "../../redux/establishment/reducers";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showCallout, setShowCallout] = useState(false);
    const [showChooseBranchModal, setShowChooseBranchModal] = useState(false)

    const dispatch = useDispatch();
    const loginReducer = useSelector(state => state.loginReducer);

    const history = useHistory();
    const establishmentBranch = useSelector(state => state.getEstablishmentBranchReducer.establishmentBranch);

    useEffect(() => {
        if (loginReducer.error || loginReducer.isAuthenticated) {
            setShowCallout(true);

            if(loginReducer.isAuthenticated) {
                if (loginReducer?.user?.branchID == null) {
                    dispatch(getEstablishmentAction());
                    setShowChooseBranchModal(true)
                } else {
                    localStorage.setItem('branchID',loginReducer.user.branchID)
                    dispatch(getEstablishmentBranchAction())
                }
            }
            setTimeout(() => {
                setShowCallout(false);
            }, 3000);
        }
    }, [loginReducer]);

    useEffect(() => {
        if(establishmentBranch) {
            localStorage.setItem('branchName', establishmentBranch?.address_display_as)
            setTimeout(() => {
                history.push('/dashboard')
                setShowCallout(false);
            }, 3000);
        }
    }, [establishmentBranch]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = () => {
        setError(null)
        if (!email || !password) {
            setError('Both fields are required');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;

        }
        if (!validateEmail(email)) {
            setError('Enter a valid email');
            setShowCallout(true);
            setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return;
        }
        dispatch(loginAction(email, password));
    };

  return (
      <div className="flex overflow-auto min-h-screen items-center" style={{backgroundColor: '#fff7e1'}}>
          <span className="text-green-600 text-4xl font-extrabold absolute top-0 left-0 ml-6 mt-6 z-10">

          </span>
          <Helmet>
              <title>Serv Restaurant Interface</title>
              <meta property="og:title" content="Serv Restaurant Interface"/>
          </Helmet>
          <div className="w-1/2 flex items-center flex-col justify-start rounded-lg p-8"
               style={{backgroundColor: '#fff7e1'}}>
              <div className="w-full flex items-start mb-8"></div>
              <div className='w-full flex flex-col items-center' style={{alignSelf: "center"}}>
                  <div className="w-full flex flex-col items-center mb-8">
                      <div className="w-full h-24 flex items-center justify-center">
                          <img
                              alt="logo"
                              src="/logos/serv-green.png"
                              className="text-[#40b06d] h-[500px] mb-28 text-5xl font-extrabold my-6"
                          />
                      </div>
                      <span className="text-gray-900 text-lg">Login to your account</span>
                  </div>
                  <div className="w-full flex flex-col items-center relative">
                      <div className="w-full mb-8 max-w-md h-16 flex flex-col">
                          <label htmlFor="Email-Input" className="text-gray-900 text-lg ml-2 mb-1">Email</label>
                          <div className="relative px-2 border border-black rounded-lg bg-fff7e1">
                              <TextInput
                                  type="text"
                                  id="Email-Input"
                                  name="Email"
                                  required={true}
                                  placeholder="example@email.com"
                                  className="text-gray-900 h-[52px] border-0 w-full bg-transparent rounded-lg"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                          </div>
                      </div>
                      <div className="w-full max-w-md h-16 flex flex-col mb-4">
                          <label htmlFor="Password-Input" className="text-gray-900 text-lg ml-2 mb-1">Password</label>
                          <div className="relative border px-2 border-black rounded-lg bg-fff7e1 flex items-center">
                              <TextInput
                                  type={"password"}
                                  id="Password-Input"
                                  name="Password"
                                  required={true}
                                  placeholder="********"
                                  className="text-gray-900 border-0 h-[52px] w-full bg-transparent rounded-lg"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                              />
                          </div>
                      </div>
                      <Link to={"/forgot-pass-email"} className="w-full max-w-md h-16 flex flex-col mt-4">
                          <span className="text-red-600 underline">Forgot Password?</span>
                      </Link>
                      <Button
                          type="button"
                          className="mt-8 px-6 py-2 w-[150px] h-[50px] text-white rounded-lg"
                          style={{ backgroundColor: '#F0532F' }}
                          onClick={handleSubmit}
                          loading={loginReducer.loading}
                      >
                          Login
                      </Button>
                  </div>
              </div>
          </div>
          <div className="w-1/2 h-screen flex items-center flex-col justify-center"
               style={{backgroundColor: '#40b06d'}}>
              <img alt="image" src="/1%204(2)-1100h.png" className="absolute top-0 right-0 w-72 object-cover"/>
              <img alt="image" src="/11%202(2)-1100h.png"
                   className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-96 object-cover"/>
              <span className="text-[#fff7e1] text-5xl font-extrabold text-center mt-4 mb-4">tap, serv, eat, enjoy</span>
              <span className="text-gray-900 text-xl font-medium text-center whitespace-pre-line"
                    style={{color: "#151e46"}}>
                    Say goodbye to waiting and hello to a new era of dining ease with serv
                </span>
          </div>
          {showCallout && (error || loginReducer.error || loginReducer.message)  && (
              <BottomCallout
                  message={error || loginReducer.error || loginReducer.message}
                  variant={error || loginReducer.error ? "error" : "success"}
              />
          )}
          {showChooseBranchModal &&
            <SelectBranchModal onClose={() => {
                setShowChooseBranchModal(false)
                history.push('/dashboard')
            }} />
          }
      </div>
  );
};

export default Login;
