import React, { useState, useEffect } from 'react';
import {updateMenuAction} from "../../../redux/menu/actions";
import {useDispatch} from "react-redux";

const CustomizationOption = ({
                                 id,
                                 name,
                                 isRequired,
                                 maxNumberOfChoices,
                                 choices,
                                 handleAddChoice,
                                 handleRemoveChoice,
                                 toggleAccordion,
                                 isOpen,
                                 handleFieldChange,
                                 handleSave
                             }) => (
    <div className="mb-4 w-full p-2">
        <div
            className="bg-[#40b06d] border-2 rounded-lg text-white w-full p-2 flex justify-between items-center cursor-pointer"
            onClick={() => toggleAccordion(id)}
        >
            <span>{name}</span>
            <button className="text-lg font-bold">{isOpen ? '⮝' : '⮟'}</button>
        </div>
        {isOpen && (
            <div className="p-4">
                <div className="mb-2">
                    <label className="block text-lg text-[#40b06d] font-bold">Question</label>
                    <input
                        type="text"
                        className="border border-gray-300 rounded-md w-full px-2 py-1"
                        value={name}
                        onChange={(e) => handleFieldChange(id, 'name', e.target.value)}
                    />
                </div>
                <div className="mb-2 flex items-center">
                    <label className="block font-bold text-lg text-[#40b06d] mr-2">Required?</label>
                    <input
                        type="checkbox"
                        checked={isRequired}
                        onChange={(e) => handleFieldChange(id, 'isRequired', e.target.checked)}
                    />
                </div>
                <div className="mb-2">
                    <label className="block text-lg text-[#40b06d] font-bold">Max Choices</label>
                    <input
                        type="number"
                        className="border border-gray-300 rounded-md w-full px-2 py-1"
                        value={maxNumberOfChoices}
                        onChange={(e) => handleFieldChange(id, 'maxNumberOfChoices', parseInt(e.target.value))}
                    />
                </div>
                <div>
                    <label className="block text-lg text-[#40b06d] font-bold mb-2">Choices</label>
                    <div className="h-32 mb-2 overflow-y-auto">
                        {choices.map((choice, index) => (
                            <div key={index} className="flex items-center mb-2">
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded-md px-2 py-1 flex-grow mr-2"
                                    value={choice.answer}
                                    onChange={(e) => handleFieldChange(id, `choices[${index}].answer`, e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded-md px-2 py-1 w-20 mr-2"
                                    value={choice.price}
                                    onChange={(e) => handleFieldChange(id, `choices[${index}].price`, e.target.value)}
                                />
                                <button
                                    onClick={() => handleRemoveChoice(id, index)}
                                    className="text-red-500 font-bold"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-between">
                        <button
                            onClick={() => handleAddChoice(id)}
                            className="text-[#40b06d] border-[#40b06d] border-2 rounded-md p-2 flex items-center"
                        >
                            <span className="mr-1">Add new</span>+
                        </button>

                        <button
                            onClick={() => handleSave(true)}
                            className="text-[#fff7e1] border-[#40b06d] bg-[#40b06d] border-2 rounded-md p-2 flex items-center"
                        >
                            <span className="mr-1">Save</span>
                        </button>
                    </div>

                </div>
            </div>
        )}
    </div>
);

const CustomizationOptions = ({
                                  data,
                                  customizationID,
                                  setCustomizationID,
                                  customizationOption,
                                  setCustomizationOption,
                                  item,
                                  categoryName
                              }) => {
    const [options, setOptions] = useState(data || []);
    const [name, setName] = useState('');
    const [isRequired, setIsRequired] = useState(false);
    const [maxNumberOfChoices, setMaxNumberOfChoices] = useState(0);
    const [choices, setChoices] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingID, setPendingID] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        if (customizationID) {
            const currentOption = options.find((option) => option.id === customizationID);
            if (currentOption) {
                setName(currentOption.name);
                setIsRequired(currentOption.is_required);
                setMaxNumberOfChoices(currentOption.max_number_of_choices);
                setChoices(currentOption.choices);
                setCustomizationOption({ ...customizationOption, name: currentOption.name, isRequired: currentOption.is_required, maxNumberOfChoices: currentOption.max_number_of_choices, choices: currentOption.choices });
            }
        }
    }, [customizationID]);

    const handleAddChoice = (id) => {
        setOptions(
            options.map((option) =>
                option.id === id
                    ? {
                        ...option,
                        choices: [...option.choices, { answer: '', price: '' }],
                    }
                    : option
            )
        );
        if (customizationID === id) {
            setChoices([...choices, { answer: '', price: '' }]);
        }
    };

    const handleNewOption = (e) => {
        e.preventDefault();
        setOptions([...options, {isNew: true, id: Date.now(), name: "", is_required: false, max_number_of_choices: 0, choices: [] }]);
    };

    const handleRemoveChoice = (optionId, choiceIndex) => {
        setOptions(
            options.map((option) =>
                option.id === optionId
                    ? {
                        ...option,
                        choices: option.choices.filter((_, index) => index !== choiceIndex),
                    }
                    : option
            )
        );
        if (customizationID === optionId) {
            setChoices(choices.filter((_, index) => index !== choiceIndex));
            setCustomizationOption({ ...customizationOption, choices: choices.filter((_, index) => index !== choiceIndex) });
        }
    };

    const toggleAccordion = (id) => {
        if (customizationID && customizationID !== id && unsavedChanges) {
            setPendingID(id);
            setIsModalOpen(true);
        } else {
            setOptions(
                options.map((option) => ({
                    ...option,
                    isOpen: option.id === id ? !option.isOpen : false,
                }))
            );
            setCustomizationID(id);
        }
    };

    const handleFieldChange = (id, field, value) => {
        setUnsavedChanges(true)
        setOptions(
            options.map((option) =>
                option.id === id
                    ? {
                        ...option,
                        [field]: value,
                    }
                    : option
            )
        );
        if (customizationID === id) {
            switch (field) {
                case 'name':
                    setName(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                case 'isRequired':
                    setIsRequired(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                case 'maxNumberOfChoices':
                    setMaxNumberOfChoices(value);
                    setCustomizationOption({ ...customizationOption, [field]: value });
                    break;
                default:
                    if (field.startsWith('choices[')) {
                        const index = parseInt(field.match(/choices\[(\d+)\]/)[1]);
                        const key = field.split('].')[1];
                        const newChoices = choices.map((choice, i) => {
                            if (i === index) {
                                return {
                                    ...choice,
                                    [key]: value,
                                };
                            }
                            return choice;
                        });
                        setChoices(newChoices);
                        setCustomizationOption({ ...customizationOption, choices: newChoices });
                    }
                    break;
            }
        }
    };

    const handleModalConfirm = (saveChanges) => {
        if (saveChanges) {
            const updateParams = {
                name: categoryName,
                item: {
                    customization: customizationOption,
                    customizationID : !customizationOption.isNew ? customizationID: undefined
                },
                itemID: item.id,
            };

            dispatch(updateMenuAction(item.category_id, updateParams));
        }
        setOptions(
            options.map((option) => ({
                ...option,
                isOpen: option.id === pendingID ? !option.isOpen : false,
            }))
        );
        setCustomizationID(pendingID);
        setUnsavedChanges(false)
        setPendingID(null);
        setIsModalOpen(false);
    };

    return (
        <div className="max-w-full mx-auto bg-beige py-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Customizations</h2>
                <button onClick={handleNewOption} className="bg-[#40b06d] text-white rounded-full px-4 py-2">Add New +</button>
            </div>
            <div className="flex flex-wrap -mx-2">
                {options?.map((option) => (
                    <div key={option.id} className="w-full md:w-1/2 l:w-1/2 xl:w-1/2 py-2">
                        <CustomizationOption
                            {...option}
                            isRequired={isRequired}
                            maxNumberOfChoices={maxNumberOfChoices}
                            handleAddChoice={handleAddChoice}
                            handleRemoveChoice={handleRemoveChoice}
                            toggleAccordion={toggleAccordion}
                            isOpen={option.isOpen}
                            choices={choices}
                            handleFieldChange={handleFieldChange}
                            handleSave={handleModalConfirm}
                        />
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-[#FFF7E1] p-4 rounded-lg shadow-lg">
                        <h3 className="text-lg font-bold mb-4">Unsaved Changes</h3>
                        <p className="mb-4">You have unsaved changes. Do you want to save or discard them before editing another option?</p>
                        <div className="flex justify-end">
                            <button onClick={() => handleModalConfirm(false)} className="bg-[#F0532F] text-white px-4 py-2 rounded-lg mr-2">Discard</button>
                            <button onClick={() => handleModalConfirm(true)} className="bg-[#40b06d] text-white px-4 py-2 rounded-lg">Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomizationOptions;
