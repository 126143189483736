import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FeedbackModal from "./FeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import BottomCallout from "./BottomCallout";
import { Link } from "react-router-dom";
import { logoutAction } from "../redux/auth/actions";
import SelectBranchModal from "./SelectBranchModal";
import { FaSyncAlt } from "react-icons/fa";

const Sidebar = (props) => {
    const [activeLink, setActiveLink] = useState('');
    const [feedbackModal, setFeedbackModal] = useState(false);
    const createFeedbackReducer = useSelector((state) => state.createFeedbackReducer);
    const { error, message, loading } = createFeedbackReducer;
    const [showCallout, setShowCallout] = useState();
    const [branchModal, setBranchModal] = useState(false);

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutAction());
        window.location.href = '/';
    };

    const onbranchModalClose = () => {
        setBranchModal(false);
        window.location.reload();
    };

    const linkClass = (link) =>
        activeLink === link ? 'text-[#40b06d] text-2xl font-semibold mt-5 mb-5 flex items-center' : 'text-[#ffffff] text-2xl font-semibold mt-5 mb-5 flex items-center';

    const currentBranch = localStorage.getItem('branchName') !== "undefined" ? localStorage.getItem('branchName') : 'Select Branch';

    return (
        <div className="relative">
            <div className="w-80 fixed h-screen top-0 left-0 self-start items-start flex-col bg-[#151e46]"
                 style={{maxHeight: '100vh'}}>
                <div className="w-full mt-10 h-24 flex items-center justify-center">
                    <img
                        alt="logo"
                        src="/logos/serv-green.png"
                        className="text-[#40b06d] h-60 text-3xl font-extrabold my-6"
                    />
                </div>
                <div className="w-full flex-1 flex items-center flex-col py-12">
                    <div
                        onClick={() => setBranchModal(true)}
                        className="text-[#ffffff] text-xl font-semibold mt-6 mb-8 flex items-center cursor-pointer"
                    >
                        {JSON.parse(localStorage.getItem('userInfo'))?.branchID == null &&
                        <div className="relative group">
                            <span className="truncate text-[#ffffff] text-3xl w-32">Select Branch</span>
                            <div
                                className="absolute hidden group-hover:block bg-[#151e46] text-[#ffffff] p-2 rounded-md shadow-lg mt-2">
                                {currentBranch}
                            </div>
                        </div>
                }
                        {/*<FaSyncAlt className="text-[#40b06d] ml-2"/>*/}
                    </div>
                    <Link to="/dashboard" className={linkClass('dashboard')}
                          onClick={() => handleLinkClick('dashboard')}>
                        <img alt={props.imageAlt6} src={props.imageSrc6} className="mr-2 h-8 w-8"/>
                        {props.text6}
                    </Link>
                    <Link to="/menu" className={linkClass('menu')} onClick={() => handleLinkClick('menu')}>
                        <img alt={props.imageAlt1} src={props.imageSrc1} className="mr-2 h-8 w-8"/>
                        {props.text1}
                    </Link>
                    <Link to="/orders" className={linkClass('orders')} onClick={() => handleLinkClick('orders')}>
                        <img alt={props.imageAlt2} src={props.imageSrc2} className="mr-2 h-8 w-8"/>
                        {props.text2}
                    </Link>
                    <Link to="/tables" className={linkClass('tables')} onClick={() => handleLinkClick('tables')}>
                        <img alt={props.imageAlt3} src={props.imageSrc3} className="mr-2 h-8 w-8"/>
                        {props.text3}
                    </Link>
                    <Link to="/reviews" className={linkClass('reviews')} onClick={() => handleLinkClick('reviews')}>
                        <img alt={props.imageAlt4} src={props.imageSrc4} className="mr-2 h-8 w-8"/>
                        {props.text4}
                    </Link>
                    <div className="w-[85%] h-32 flex p-0 mt-6 items-center rounded-2xl flex-col bg-[#40b06d]">
                        <span
                            className="text-[#fff7e1] text-center text-base font-medium pt-6 px-6 pb-2">{props.text7}</span>
                        <button onClick={() => setFeedbackModal(true)} type="button"
                                className="text-[#40b06d] font-semibold border-0 w-[150px] h-auto rounded-xl bg-[#fff7e1]">
                            {props.button}
                        </button>
                    </div>
                </div>
                <div className="gap-8 flex w-full items-center justify-center">
                    <button className={linkClass('logout')} onClick={() => handleLogout()}>
                        <img alt={props.imageAlt8} src={props.imageSrc8} className="mr-2 h-8 w-8"/>
                        {props.text5}
                    </button>
                </div>
            </div>
            {feedbackModal && <FeedbackModal setShowCallout={setShowCallout} onClose={setFeedbackModal}/>}
            {branchModal && <SelectBranchModal canClose={true} onClose={onbranchModalClose}/>}
            {showCallout && (error || message) && (
                <BottomCallout
                    setShowCallout={setShowCallout}
                    message={error || message}
                    variant={error ? "error" : "success"}
                />
            )}
        </div>
    );
};

Sidebar.defaultProps = {
    text: 'SERV',
    text4: 'Reviews',
    text2: 'Orders',
    text5: 'Logout',
    text3: 'Tables',
    imageSrc: '/logout-2-svgrepo-com.svg',
    imageAlt: 'image',
    button: 'Rate Experience',
    text7: 'Help us improve our services by sharing your thoughts',
    text6: 'Dashboard',
    text1: 'Menu',
    imageSrc6: '/menu-alt-2-svgrepo-com%20(2).svg',
    imageSrc1: '/home-20-svgrepo-com.svg',
    imageSrc2: '/menu-wines-wine-svgrepo-com.svg',
    imageSrc3: '/food-dinner-dish-plate-fork-svgrepo-com.svg',
    imageSrc4: '/menu-board-svgrepo-com.svg',
    imageSrc8: '/logout-2-svgrepo-com.svg',
};

Sidebar.propTypes = {
    text: PropTypes.string,
    text4: PropTypes.string,
    text2: PropTypes.string,
    text5: PropTypes.string,
    text3: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    button: PropTypes.string,
    text7: PropTypes.string,
    text6: PropTypes.string,
    text1: PropTypes.string,
    imageSrc6: PropTypes.string,
    imageSrc1: PropTypes.string,
    imageSrc2: PropTypes.string,
    imageSrc3: PropTypes.string,
    imageSrc4: PropTypes.string,
    imageSrc8: PropTypes.string,
};

export default Sidebar;
