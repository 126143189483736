import axios from "axios";
import baseURL from "../baseURL";
import {
    ITEM_AVAILABILITY_UPDATE_FAIL,
    ITEM_AVAILABILITY_UPDATE_REQUEST, ITEM_AVAILABILITY_UPDATE_SUCCESS,
    LIST_MENU_FAIL, LIST_MENU_REQUEST, LIST_MENU_SUCCESS, MENU_ADD_FAIL, MENU_ADD_REQUEST, MENU_ADD_SUCCESS,
    MENU_UPDATE_FAIL,
    MENU_UPDATE_REQUEST,
    MENU_UPDATE_SUCCESS,
} from "./constants";

export const listMenuAction = (branchID) => async (dispatch) => {
    try {
        dispatch({
            type: LIST_MENU_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };
        const branchID = parseInt(localStorage.getItem("branchID")) === 0 ? parseInt(localStorage.getItem('defaultBranchID')) : parseInt(localStorage.getItem('branchID'))
        const { data } = await axios.get(
            `${baseURL}/menus/categories/${branchID}`,
            config
        );

        dispatch({
            type: LIST_MENU_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIST_MENU_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const updateMenuAction = (categoryID, updateParams, imageFile) => async (dispatch) => {
    try {
        dispatch({
            type: MENU_UPDATE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(updateParams));
        if (imageFile) {
            formData.append('image', imageFile);
        }
        const { data } = await axios.patch(
            `${baseURL}/menus/${categoryID}`,
            formData,
            config
        );

        dispatch({
            type: MENU_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: MENU_UPDATE_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const addMenuItemAction = (addParams, imageFile) => async (dispatch) => {
    try {
        dispatch({
            type: MENU_ADD_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(addParams));
        if (imageFile) {
            // Hardcoded 0 for now since we add one item per req
            formData.append('image0', imageFile);
        }

        const { data } = await axios.post(
            `${baseURL}/menus`,
            formData,
            config
        );

        dispatch({
            type: MENU_ADD_SUCCESS,
            payload: data,
        });
    } catch (error) {

        dispatch({
            type: MENU_ADD_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};

export const updateItemAvailability = (branchID, itemID, availability) => async (dispatch) => {
    try {
        dispatch({
            type: ITEM_AVAILABILITY_UPDATE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        };

        // Example payload for PATCH request
        const payload = {
            isAvailable: availability,
        };

        const { data } = await axios.patch(
            `${baseURL}/menus/${branchID}/${itemID}`,
            payload,
            config
        );

        dispatch({
            type: ITEM_AVAILABILITY_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ITEM_AVAILABILITY_UPDATE_FAIL,
            payload: error.response
                ? error.response.data.message
                : 'Network Error',
        });
    }
};