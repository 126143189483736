import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {listMenuAction, updateItemAvailability} from '../../redux/menu/actions';
import Sidebar from '../../components/Sidebar';
import BottomCallout from '../../components/BottomCallout';
import {Link} from "react-router-dom";
import NewItemModal from './components/NewItemModal';

const Menu = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const menuList = useSelector((state) => state.listMenuReducer);
    const itemAvailabilityUpdateReducer = useSelector((state) => state.itemAvailabilityUpdateReducer);
    const { menu, error } = menuList;

    const [selectedCategory, setSelectedCategory] = useState('all');
    const categoryNames = menu ? menu.map(category => ({ id: category.id, name: category.categoryName })) : [];
    const filteredMenu = selectedCategory === 'all' ? menu : menu?.filter(category => category.categoryName === selectedCategory);

    useEffect(() => {
        dispatch(listMenuAction());
    }, [dispatch,itemAvailabilityUpdateReducer]);

    // TODO: initial loading only

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };


    return (
        <div className="flex min-h-screen flex-row justify-between bg-[#fff7e1]">
            <Helmet>
                <title>Menu - Serv Restaurant Interface</title>
                <meta property="og:title" content="Menu - Serv Restaurant Interface" />
            </Helmet>
            <Sidebar />
            <div className="ml-80 flex flex-col items-center justify-start bg-[#fff7e1] w-full overflow-hidden px-6 py-12">
                <div className="flex justify-between items-start w-full mb-8">
                    <span className="text-[#151e46] text-5xl font-bold">Menu</span>
                    <div onClick={() => setIsModalOpen(true)}
                         className="flex items-center justify-center bg-[#151e46] rounded-2xl px-6 py-3">
                        <img alt="image" src="/new-tab-svgrepo-com.svg" className="w-9 object-cover mr-2"/>
                        <button
                            className="text-[#fff7e1] text-xl"
                        >
                            New Item
                        </button>
                    </div>
                </div>
                <div className="w-full flex mb-8">
                    <div className="flex overflow-x-auto p-4 space-x-4 w-full">
                        <button
                            onClick={() => handleCategoryChange('all')}
                            className={`py-2 px-4 rounded ${selectedCategory === 'all' ? 'bg-[#151e46] rounded-2xl text-white' : 'bg-white rounded-2xl text-[#151e46]'}`}
                        >
                            All
                        </button>
                        {categoryNames.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => handleCategoryChange(category.name)}
                                className={`py-2 px-4 rounded ${selectedCategory === category.name ? 'bg-[#151e46] text-white rounded-2xl' : 'bg-white text-[#151e46] rounded-2xl'}`}
                            >
                                {category.name}
                            </button>
                        ))}
                    </div>
                </div>


                {error ? (
                    <BottomCallout
                        message={error}
                        variant="error"
                    />
                ) : (
                    filteredMenu && filteredMenu.map((category) => (
                        <div key={category.id} className="w-full mb-8">
                            <div className="flex justify-between items-center w-full mb-6">
                                <span className="text-[#151e46] text-4xl font-medium">{category.categoryName}</span>
                            </div>
                            <div
                                className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-6 w-full">
                                {category.menuItems?.map((item) => (
                                    <div key={item.id} className="bg-[#d7f1df] rounded-lg p-4">
                                        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
                                            <img alt="image" src={item.image_url}
                                                 className="w-24 h-24 object-cover mb-4 sm:mb-0 sm:mr-4"/>
                                            <div className="flex flex-col w-full">
                                                <div className="flex justify-between items-center mb-2">
                                                    <span
                                                        className="text-[#151e46] text-xl font-semibold">{item.name}</span>
                                                    <span
                                                        className="text-[#151e46] text-lg font-medium">{item.price} EGP</span>
                                                </div>
                                                <span className="text-[#151e46] text-base">{item.description}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center w-full">

                                            <div className="flex items-center">
                                                {localStorage.getItem('branchID') !== "0" &&
                                                    <>
                                                        <img alt="image"
                                                             onClick={(event) => {
                                                                 dispatch(updateItemAvailability(localStorage.getItem("branchID"), item.id, !item.is_available));
                                                             }}

                                                             src={item.is_available ? '/toggle-on-svgrepo-com.svg' : '/toggle-off-svgrepo-com.svg'}
                                                             className="w-12 h-12 mr-2"/>
                                                        <span
                                                            className="text-[#151e46] text-sm">{item.is_available ? 'Available' : 'Unavailable'}</span>
                                                    </>
                                                }
                                            </div>

                                            <div className="flex items-center">
                                                <Link to={{
                                                    pathname: `/menu-item-responsive`,
                                                    state: {item, categoryName: category.categoryName}
                                                }}
                                                      key={item.id}
                                                >
                                                    <img alt="image" src="/edit-02-svgrepo-com (1).svg"
                                                         className="w-6 h-6 mr-2 cursor-pointer"/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <br/>
                            <hr/>
                        </div>

                    ))
                )}
            </div>
            {isModalOpen &&
                <NewItemModal
                    onClose={() => setIsModalOpen(false)}
                    categoryNames={categoryNames}
                />
            }
        </div>
    );
};

export default Menu;
